import * as API from '@/api/index'

export default {
    //保存页面数据
    creat:params=>{
        return API.POST("api/integral/create",params)
    },
    //页面数据
    list:params=>{
        return API.POST("api/integral/all",params)
    },
    //分类积分数据
    allCategory:params=>{
        return API.POST("api/integral/allCategory",params)
    },
    //保存分类积分数据
    createGoodCategory:params=>{
        return API.POST("api/integral/createGoodCategory",params)
    },
     //分类商品数据
     allGood:params=>{
        return API.POST("api/integral/allGood",params)
    },
    //保存分类商品数据
    createGood:params=>{
        return API.POST("api/integral/createGood",params)
    },
     //积分抵扣
     allDeduction:params=>{
        return API.POST("api/integral/allDeduction",params)
    },
    //保存积分抵扣
    createDeduction:params=>{
        return API.POST("api/integral/createDeduction",params)
    }


}