<template>
  <div class="Integral content_body color_333">
    <!-- <div class="top border_bottom">积分规则设置</div> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="积分规则设置" name="0">
        <el-form ref="form" :model="formData" label-width="80px">
          <div
            class="back_f8 martp_20 marbm_20 dis_flex flex_y_center flex_x_between"
          >
            <div class="label dis_flex flex_y_center">启用积分</div>
            <el-switch v-model="formData.integralBool"></el-switch>
          </div>
          <el-form-item
            label="积分有效期"
            required
            label-width="110px"
            class="active"
          >
            <div style="line-height: 40px">
              <el-radio v-model="integralRadio" label="1">永久有效</el-radio>
            </div>
            <div style="line-height: 40px" class="dis_flex flex_y_center">
              <el-radio v-model="integralRadio" label="2">每年</el-radio>
              <div class="dis_flex flex_y_center">
                <el-select
                  :disabled="integralRadio == 1 || integralRadio == 3"
                  size="small"
                  v-model="formData.month"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in 12"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="mar_0_10">月</div>
                <el-select
                  size="small"
                  v-model="formData.day"
                  placeholder="请选择"
                  :disabled="integralRadio == 1 || integralRadio == 3"
                >
                  <el-option
                    v-for="item in days"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="mar_0_10">日</div>
                <span>自动过期</span>
              </div>
            </div>
            <div style="line-height: 40px" class="dis_flex flex_y_center">
              <el-radio v-model="integralRadio" label="3">逐笔过期：</el-radio>
              <span>每笔自发放之日起</span>
              <el-input
                type="number"
                style="width: 70px; margin: 0 5px"
                v-model="formData.numDays"
                size="small"
                :disabled="integralRadio == 1 || integralRadio == 2"
              ></el-input>
              <span>天后过期</span>
            </div>
          </el-form-item>
          <el-form-item label="完成预约产生" required label-width="110px">
            <el-input
              type="number"
              style="width: 70px; margin: 0 5px"
              v-model="formData.ApptIntegral"
              size="small"
            ></el-input>
            <span>积分/次</span>
          </el-form-item>
          <!-- <el-form-item label="参与订单评价" required label-width="110px">
        <el-input style="width: 70px;margin:0 5px" v-model="formData.numDays" size="small"></el-input>
        <span>积分/次</span>
      </el-form-item> -->
          <el-table :data="formData.tableData" style="width: 90%">
            <el-table-column
              prop="GoodsTypeID"
              label="商品类型"
              :formatter="formatType"
            >
            </el-table-column>
            <el-table-column label="门店销售现金支付1元产生积分">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="small"
                  v-model="scope.row.SalePayIntegral"
                  style="width: 100px"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="门店销售卡扣1元产生积分">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="small"
                  v-model="scope.row.SaleSavingDeductionCardIntegral"
                  style="width: 100px"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="门店销售赠送卡扣1元产生积分">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="small"
                  v-model="scope.row.SaleLargessSavingDeductionCardIntegral"
                  style="width: 100px"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="网店销售现金支付1元产生积分">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="small"
                  v-model="scope.row.WXSalePayIntegral"
                  style="width: 100px"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="消耗现金支付1元产生积分">
              <template slot-scope="scope">
                <el-input
                  type="number"
                  size="small"
                  v-model="scope.row.WXConsumeIntegral"
                  style="width: 100px"
                  placeholder="请输入"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="setTypeIntegral(scope.row)"
                  >设置分类产生积分</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="积分抵现" name="1">
        <el-form ref="form" :model="intToCashFormData" label-width="120px">
          <el-form-item label="门店积分抵现">
            <div>
              <el-radio
                v-model="intToCashFormData.IsEntityDeduction"
                :label="false"
                >关闭</el-radio
              >
            </div>
            <div class="dis_flex flex_y_center">
              <el-radio
                v-model="intToCashFormData.IsEntityDeduction"
                :label="true"
                >开启</el-radio
              >
              <span>1积分=</span>
              <el-input
              type="number"
                size="small"
                :disabled="intToCashFormData.storeRadio == 0"
                v-model="intToCashFormData.EntityDeductionCash"
                style="width: 120px"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="网店积分抵现">
            <div>
              <el-radio v-model="intToCashFormData.IsWXDeduction" :label="false"
                >关闭</el-radio
              >
            </div>
            <div class="dis_flex flex_y_center">
              <el-radio v-model="intToCashFormData.IsWXDeduction" :label="true"
                >开启</el-radio
              >
              <span>1积分=</span>
              <el-input
                size="small"
                :disabled="intToCashFormData.IsWXDeduction == false"
                v-model="input"
                type="number"
                style="width: 120px"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="saveBtn dis_flex flex_x_center flex_y_center">
      <el-button size="small" type="primary" @click="saveBtn">保存</el-button>
    </div>
    <!-- 设置分类 产生积分弹出层 -->
    <el-dialog
      title="设置分类产生积分"
      :visible.sync="TypeDialogVisible"
      width="80%"
    >
      <!-- <div style="height:60vh">
 <el-scroll class="el-scrollbar_height"> -->
      <el-table
        :data="formData.setTypeTableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="CategoryID"
        :tree-props="{ children: 'Child' }"
        height="55vh"
      >
        <el-table-column
          prop="CategoryName"
          label="产品分类"
          sortable
          width="180"
        >
        </el-table-column>
        <el-table-column label="门店销售现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.SalePayIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售卡扣1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.SaleSavingDeductionCardIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售赠送卡扣1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.SaleLargessSavingDeductionCardIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="网店销售现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.WXSalePayIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="消耗现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.WXConsumeIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.children"
              type="primary"
              size="small"
              @click="setProductIntegral(scope.row)"
              >设置产品产生积分</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- </el-scroll>
    </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="TypeDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="setType"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置产品产生积分弹出层 -->
    <el-dialog
      title="设置产品产生积分"
      size="small"
      :visible.sync="ProductDialogVisible"
      width="80%"
      @
    >
      <el-table
        :data="formData.setProductTableData"
        style="width: 100%; margin-bottom: 20px"
        height="55vh"
      >
        <el-table-column prop="GoodsName" label="产品名称" sortable width="180">
        </el-table-column>
        <el-table-column label="门店销售现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.SalePayIntegral"
              style="width: 100px"
              type="number"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售卡扣1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.SaleSavingDeductionCardIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="门店销售赠送卡扣1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.SaleLargessSavingDeductionCardIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="网店销售现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.WXSalePayIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="消耗现金支付1元产生积分">
          <template slot-scope="scope">
            <el-input
              type="number"
              size="small"
              v-model="scope.row.WXConsumeIntegral"
              style="width: 100px"
              placeholder="请输入"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="ProductDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="ProductSavebtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../../api/Marketing/MarketingPlay/Integral";
export default {
  name: "Integral",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      input: "",
      activeName: 0,
      integralRadio: "1",
      GoodsTypeID: "", //分类积分ID
      formData: {
        integralBool: true,
        month: 12,
        day: 1,
        numDays: "",
        tableData: [],
        setTypeTableData: [],
        setProductTableData: [],
      },
      intToCashFormData: {
        IsEntityDeduction: true,
        IsWXDeduction: true,
        EntityDeductionCash: "",
        WXDeductionCash: "",
      },
      TypeDialogVisible: false,
      ProductDialogVisible: false,
    };
  },
  /**计算属性  */
  computed: {
    days: function () {
      const month = this.formData.month;
      const num1 = [1, 3, 5, 7, 8, 10, 12].findIndex((item) => item == month);
      const num2 = [4, 6, 9, 11].findIndex((item) => item == month);
      if (num1 != -1) {
        return 31;
      } else if (num2 != -1) {
        return 30;
      } else {
        return 28;
      }
    },
  },
  /**  方法集合  */
  methods: {
    formatType(row) {
      var type = "";
      switch (Number(row.GoodsTypeID)) {
        case 1:
          type = "产品";
          break;
        case 2:
          type = "项目";
          break;
        case 3:
          type = "通用次卡";
          break;
        case 4:
          type = "时效卡";
          break;
        case 5:
          type = "储值卡";
          break;
        case 6:
          type = "套餐卡";
          break;
        default:
          break;
      }
      return type;
    },
    // 分类产生积分弹窗
    setTypeIntegral(row) {
      var that = this;
      that.GoodsTypeID = row.GoodsTypeID;
      var params = { GoodsTypeID: row.GoodsTypeID };
      API.allCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.formData.setTypeTableData = res.Data;
          this.TypeDialogVisible = true;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 保存分类积分
    setType() {
      var that = this;

      var Good = that.deepCopy(that.formData.setTypeTableData);
      var childList = [];
      Good.forEach((item) => {
        if (item.Child) {
          childList.push(...item.Child);
        }
      });
      Good.push(...childList);
      Good.forEach((item) => {
        if (item.Child) {
          delete item.Child;
        }
      });
      var params = {
        GoodsTypeID: that.GoodsTypeID,
        Good: Good,
      };
      API.createGoodCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已保存!");
          that.TypeDialogVisible = false;
        }
      });
    },
    // 设置产品产生积分弹窗
    setProductIntegral(row) {
      var that = this;
      var params = {
        GoodsTypeID: that.GoodsTypeID,
        CategoryID: row.CategoryID,
      };
      API.allGood(params).then((res) => {
        if (res.StateCode == 200) {
          that.formData.setProductTableData = res.Data;
          this.ProductDialogVisible = true;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 保存产品积分
    ProductSavebtn() {
      var that = this;
      const { GoodsTypeID, formData } = that;
      var params = {
        GoodsTypeID,
        Good: formData.setProductTableData,
      };
      API.createGood(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("保存成功!");
          that.ProductDialogVisible = false;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 保存页面数据
    saveBtn() {
      var that = this;
      if (that.activeName == 0) {
        //积分规则设置
        if (that.integralRadio == 1) {
          this.formData.month = "";
          this.formData.day = "";
          this.formData.numDays = "";
        } else if (that.integralRadio == 2) {
          this.formData.numDays = "";
        } else {
          this.formData.month = "";
          this.formData.day = "";
        }
        let params = {
          TermValidity: that.integralRadio,
          EachYearMonth: that.formData.month,
          EachYearDay: that.formData.day,
          ApptIntegral: that.formData.ApptIntegral,
          OverdueIntegral: that.formData.numDays,
          Active: that.formData.integralBool,
          Good: that.formData.tableData,
        };
        API.creat(params).then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("已保存!");
            that.getList();
          } else {
            that.$message.error(res.Message);
          }
        });
      } else {
        const {
          IsEntityDeduction,
          IsWXDeduction,
          EntityDeductionCash,
          WXDeductionCash,
        } = that.intToCashFormData;
        //积分抵现
        let params = {
          IsEntityDeduction,
          IsWXDeduction,
          EntityDeductionCash,
          WXDeductionCash,
        };
        API.createDeduction(params).then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("已保存!");
            that.getDeduction();
          } else {
            that.$message.error(res.Message);
          }
        });
      }
    },
    // 展示
    getList() {
      var that = this;
      API.list().then((res) => {
        if (res.StateCode == 200) {
          const data = res.Data;
          that.integralRadio = data.TermValidity;
          that.formData.month = data.EachYearMonth;
          that.formData.day = data.EachYearDay;
          that.formData.numDays = data.OverdueIntegral;
          that.formData.ApptIntegral = data.ApptIntegral;
          that.formData.tableData = data.Good;
        }
      });
    },
    // 积分抵现
    getDeduction(){
      API.allDeduction().then(res=>{
        var that = this;
        if (res.StateCode == 200) {
          const {EntityDeductionCash,IsEntityDeduction,IsWXDeduction,WXDeductionCash} = res.Data;
          that.intToCashFormData = Object.assign({},{EntityDeductionCash,IsEntityDeduction,IsWXDeduction,WXDeductionCash})
        }else{
           that.$message.error(res.Message)
         }
      })
    },
    // 深拷贝
    deepCopy(obj) {
      var that = this;
      // 判断传入目标 类型
      if (typeof obj !== "object" || obj === null) {
        return obj;
      } else {
        var result = obj instanceof Array ? [] : {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            result[key] = that.deepCopy(obj[key]);
          }
        }
        return result;
      }
    },
  },

  /** 监听数据变化   */
  watch: {
    activeName(n,o){
      if (n == 0) {
        this.getList();
      } else {
        this.getDeduction();
      }
    }
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.getList();
    this.getDeduction();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style  lang="scss">
.Integral {
  min-width: 600px;
  padding-bottom: 50px;
  .top {
    color: #018dff;
    height: 50px;
    line-height: 35px;
  }
  .label::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    margin: 10px 10px;
    background-color: #018dff;
  }
  .el-switch {
    margin-right: 30px;
  }
  .active {
    .el-select {
      width: 90px;
    }
  }
}
.saveBtn {
  height: 50px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 90%;
  z-index: 111;
  box-sizing: border-box;
  padding-right: 20%;
}
.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .is-horizontal {
    display: none;
  }
}
</style>
